import React, { FC } from 'react';

export interface MenuSeparatorProps {
  title: string;
}

export const MenuSeparator: FC<MenuSeparatorProps> = ({ title }) => (
  <div className="flex gap-2 items-center my-2 pl-3">
    <h3 className="italic text-2xl">{title}</h3>
    <div className="h-[1px] bg-[#fff] w-full my-2" />
  </div>
);
