import React, { FC } from 'react';
import CardsFilters from '@homepage/components/organisms/CardsFilters';
import CardList from '@homepage/components/organisms/CardList';
import CourseModal from '@homepage/components/organisms/CourseModal';
import { useCourseModalStore } from '@homepage/store/courseModal.store';
import { CoursesListProps } from '@common/types/courseListProps.interface';
import { Pagination } from '@common/components/organisms/Pagination/Pagination';
import { CardsFiltersProps } from '@homepage/components/organisms/CardsFilters/CardsFilters';
import { Spinner } from '@common/components/atoms/Loaders';

export interface PublicMainProps extends CoursesListProps, CardsFiltersProps {}

export const PublicMain: FC<PublicMainProps> = ({
  courses, coursesCount, setNextPage, taking, control, isLoading, onSubmit,
}) => {
  const { isOpen } = useCourseModalStore((state) => ({
    isOpen: state.isOpen,
    course: state.course,
  }));

  return (
    <section className="p-5">
      <CardsFilters control={control} onSubmit={onSubmit} />
      {
        isLoading ? (
          <div className="w-min mx-auto my-10">
            <Spinner size="xxl" />
          </div>
        ) : <CardList courses={courses} />
      }

      <Pagination
        showing={courses.length}
        total={coursesCount}
        pageCount={Math.ceil(coursesCount / taking)}
        onPageChange={setNextPage}
      />
      { isOpen && <CourseModal /> }
    </section>
  );
};
