import React from 'react';
import { useController, UseControllerProps } from 'react-hook-form';

export const inputClasses = 'border-unaula-orange border-[1px] border-solid p-2 rounded-xl';

export type InputProps = UseControllerProps &
{
  placeholder?: string,
  className?: string,
  type: string,
  id?: string,
  defaultValue?: any,
};

export const Input:
React.FC<InputProps> = (
  {
    className, type, placeholder, id, ...props
  },
) => {
  const { field, fieldState } = useController({ ...props });
  return (
    <>
      <input
        className={inputClasses.concat(' ', className, ' ', fieldState.error ? 'border-unaula-red' : '')}
        placeholder={placeholder}
        type={type}
        id={id ?? props.name}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...field}
      />
      <span className="text-unaula-red">{fieldState.error?.message}</span>
    </>
  );
};

Input.defaultProps = {
  className: '',
  placeholder: '',
  id: undefined,
  defaultValue: undefined,
};
