import AdminContainer from '@commonPrivate/components/atoms/AdminContainer';
import CoursesTable from '@courses/components/organisms/CoursesTable';
import React, { FC } from 'react';
import { CoursesTableProps } from '@courses/components/organisms/CoursesTable/CoursesTable';

export const Courses: FC<CoursesTableProps> = (props) => (
  <AdminContainer>
    <h1 className="text-2xl font-bold text-center">Servicios de información</h1>

    <p className="text-center">
      Acá encontrarás todos los cursos, seminarios, talleres y diplomados. Puedes
      gestionarlos para editarlos o eliminarlos.
    </p>

    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
    <CoursesTable {...props} />
  </AdminContainer>
);
