import { AxiosResponse } from 'axios';
import { useState } from 'react';

export const useRequest = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const sendRequest = async <T>(post: Promise<AxiosResponse<T>>) => {
    setIsLoading(true);
    setIsError(false);

    try {
      const response = await post;
      return response;
    } catch (error) {
      setIsError(true);
      if (error?.response) return error.response;
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  return { sendRequest, isLoading, isError };
};
