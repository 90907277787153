import { buttonTheme } from '@common/components/atoms/Buttons/common/priorityProps';
import { CloseIcon, PersonIcon } from '@common/components/atoms/icons';
import { TagStyle } from '@courseForm/components/molecules/TagInput/TagInput';
import CourseTitle from '@homepage/components/molecules/CourseTitle';
import CourseType from '@homepage/components/molecules/CourseType';
import { useCourseModalStore } from '@homepage/store/courseModal.store';
import React from 'react';

const formatter = new Intl.NumberFormat('es-CO', {
  style: 'currency',
  currency: 'COP',
  minimumFractionDigits: 2,
});

export const CourseModal = () => {
  const { closeModal, course } = useCourseModalStore((state) => ({
    closeModal: state.closeModal,
    course: state.course,
  }));

  if (!course) return null;

  return (
    <div className="w-full h-full fixed top-0 left-0 py-10 backdrop-blur-sm">
      <div className="w-11/12 lg:w-5/12 m-auto h-full items-center border-solid border-unaula-orange border-[1px] overflow-y-auto bg-unaula-gray rounded-2xl">
        <div className="relative">
          <img src={course.image as string} className="w-full object-cover" alt="course" />
          { /* eslint-disable-next-line jsx-a11y/control-has-associated-label */ }
          <button
            type="button"
            className="absolute top-3 right-3"
            onClick={() => closeModal()}
          >
            <CloseIcon className="w-6 h-6 fill-unaula-orange" />
          </button>
        </div>

        <div className="p-5 m-5 flex flex-col gap-4 rounded-2xl shadow-md">
          <CourseTitle title={course.name} align="center" />

          <span className="flex gap-2 items-center my-2">
            <PersonIcon className="fill-unaula-orange" />
            Por
            {' '}
            {course.exhibitorName}
          </span>

          <p>{course.generalInfo}</p>

          <CourseType type={course.type} />

          <p className="bg-gray-100 p-2 text-sm">
            {course.hours}
            {' '}
            horas en total
          </p>

          <div>
            <div className="font-semibold mb-2">Temario:</div>
            <div className="flex flex-wrap gap-2">
              {
                course.syllabus.split(';').map((tag) => (
                  <span className={TagStyle.concat(' w-max text-xs')} key={`key-${tag}`}>{tag}</span>
                ))
              }
            </div>
          </div>

          <hr />

          <p className="flex gap-2">
            <span>Costo total:</span>
            <span className="font-bold">{formatter.format(course.cost)}</span>
          </p>

          {course.discounts && (
            <p className="flex gap-2">
              <span>Descuentos aplicables:</span>
              <span>
                {course.discounts}
                {' '}
                <span className="text-unaula-red">*</span>
              </span>
            </p>
          )}

          <a
            href={course.inscriptionUrl}
            target="_blank"
            className={buttonTheme.primary.concat(' m-auto block')}
            rel="noreferrer"
          >
            Inscribirme
          </a>

          <hr />

          <div className="flex gap-2">
            <div className="w-1/2 m-auto">
              <div className="text-sm text-center italic mb-2 line-clamp-2">
                Dictado por
                {' '}
                {course.exhibitorName}
              </div>
              <img src={course.exhibitorPhoto as string} className="h-40 w-52 object-contain bg-white" alt="" />
            </div>
            <div className="w-1/2 m-auto">
              <div className="text-sm text-center italic mb-2 line-clamp-2">
                Apoyado por
                {' '}
                {course.supportedByName}
              </div>
              <img src={course.supportedByPhoto as string} className="h-40 w-52 object-contain bg-white" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
