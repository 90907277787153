import { useCourses } from '@common/hooks/useCourses';
import { Filter, parseParams } from '@common/utils/paramsFilter.parser';
import { HomeFiltersFields } from '@homepage/components/organisms/CardsFilters/CardsFilters';
import PublicMain from '@homepage/components/templates/PublicMain';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

export const HomePage = () => {
  const [queryFilters, setQueryFilters] = useState<string>('');

  const {
    courses, coursesCount, isError, isLoading, setNextPage, taking,
  } = useCourses({ defaultSkip: 0, defaultTaking: 10, queryFilters });

  const { control, handleSubmit } = useForm<HomeFiltersFields>({
    mode: 'onChange',
    defaultValues: { search: '', type: '' },
  });

  const onSubmit = ({ search, type }: HomeFiltersFields) => {
    const filters: Filter[] = [];

    if (search && search.length > 3) filters.push({ column: 'name', operation: 'ilike', value: search });
    if (type) filters.push({ column: 'type', operation: 'equals', value: type });

    return setQueryFilters(parseParams(filters));
  };

  // TODO: use HOC component to render this error repeat multiple times.
  if (isError) return <p className="text-center m-auto py-32">Upss, ha ocurrido un error inesperado.</p>;

  return (
    <PublicMain
      courses={courses}
      coursesCount={coursesCount}
      setNextPage={setNextPage}
      isLoading={isLoading}
      taking={taking}
      onSubmit={handleSubmit(onSubmit)}
      control={control}
    />
  );
};
