import { AuthStore, tokenStorageKey } from '@common/store/auth.store';
import { getStorage } from '@common/utils/localStorage.utils';
import axios, { HttpStatusCode } from 'axios';

export const pygloApi = axios.create({
  baseURL: process.env.REACT_APP_PUBLIC_PYGLO_URL,
  headers: { 'Content-Type': 'application/json' },
});

pygloApi.interceptors.request.use((config) => {
  const { isAuthenticated } = AuthStore.getState();

  if (isAuthenticated) {
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `Bearer ${getStorage(tokenStorageKey)}`;
  }

  return config;
});

pygloApi.interceptors.response.use((response) => response, async (error) => {
  if (error?.response?.status === HttpStatusCode.Unauthorized) {
    AuthStore.getState().logout();
  }

  await Promise.reject(error.response);
});
