import React, {
  FC, useEffect, useState,
} from 'react';
import { Control, UseFormSetValue } from 'react-hook-form';
import { Input } from '@common/components/molecules/Inputs';
import { Button } from '@common/components/atoms/Buttons';
import { inputClasses } from '@common/components/molecules/Inputs/Input';
import { CloseIcon } from '@common/components/atoms/icons';
import { CourseFormFields } from '@courseForm/types/courseFormFields';

export const TagStyle = 'border-[black] border-[1px] p-2 rounded-xl flex items-center';

export interface TagInputProps {
  control: Control<any, any>;
  setValue: UseFormSetValue<CourseFormFields>,
}
export const TagInput: FC<TagInputProps> = ({ control, setValue }) => {
  const [addedTags, setAddedTags] = useState<string[]>();
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    if (addedTags) {
      setValue('syllabus', addedTags.join(','), { shouldValidate: true });
    }
  }, [addedTags]);

  const onNewTag = () => {
    if (inputValue) {
      setAddedTags(
        (oldAddedTags) => (
          oldAddedTags ? [...oldAddedTags, inputValue] : [inputValue]
        ),
      );
      setInputValue('');
    }
  };

  const onRemoveTag = (tag: string) => {
    setAddedTags(addedTags.filter((addedTag) => addedTag !== tag));
  };

  return (
    <div className="flex flex-wrap gap-2 items-center">
      <input
        type="text"
        placeholder="Escribe un tema"
        className={inputClasses}
        onChange={(e) => setInputValue(e.target.value)}
        value={inputValue}
      />
      <Button type="button" onClick={onNewTag}>Nuevo</Button>

      {
        addedTags?.map((tag) => (
          <span className={TagStyle} key={`key-${tag}`}>
            {tag}
            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
            <button type="button" onClick={() => onRemoveTag(tag)}>
              <CloseIcon className="cursor-pointer ml-2" />
            </button>
          </span>
        ))
      }

      <Input
        name="syllabus"
        type="hidden"
        control={control}
        rules={{ required: 'Debes agregar un tema' }}
      />
    </div>
  );
};
