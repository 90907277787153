import React from 'react';
import { BarsIcon, PersonIcon } from '@common/components/atoms/icons';
import { ICourse } from '@commonPrivate/types/course.interface';
import CourseTitle from '@homepage/components/molecules/CourseTitle';
import { useCourseModalStore } from '@homepage/store/courseModal.store';
import { serviceTypeMapper } from '@common/utils/serviceTypeMapper.util';

export interface CourseCardProps {
  course: ICourse;
}

export const CourseCard: React.FC<CourseCardProps> = ({ course }) => {
  const openModal = useCourseModalStore((state) => state.openModal);

  return (
    <div
      className="border-unaula-orange border-solid rounded-2xl border-[1px]
      w-[384px] h-80 overflow-hidden shadow-lg mb-8 relative"
    >
      <div className="w-full h-44">
        <img src={course.image as string} alt="course" className="w-full h-[100%] object-cover" />
      </div>

      <div className="p-4 bg-unaula-gray h-36 flex flex-col justify-between">
        <CourseTitle title={course.name} />

        <div className="py-2 px-4 rounded-ee-2xl absolute top-0 left-0 bg-opacity-75 bg-white">
          <div className="font-bold">
            {serviceTypeMapper(course.type)}
          </div>
        </div>

        <div className="flex">
          <div className="flex items-center gap-1 text-ellipsis overflow-hidden text-nowrap">
            <PersonIcon className="fill-unaula-orange" />
            <span className="overflow-hidden text-ellipsis mr-2">{course.exhibitorName}</span>
          </div>

          <button
            className="ml-auto flex items-center gap-1"
            type="button"
            onClick={() => openModal(course)}
          >
            <BarsIcon className="fill-unaula-orange" />
            <span className="underline w-max">Ver detalles</span>
          </button>
        </div>
      </div>
    </div>
  );
};
