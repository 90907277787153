import { serviceTypeMapper } from '@common/utils/serviceTypeMapper.util';
import React, { FC } from 'react';

export interface CourseTypeProps {
  type: string;
}

const CourseType: FC<CourseTypeProps> = ({ type }) => (
  <div className="flex text-sm">
    <div className="p-2  bg-gray-200">Tipo de servicio de formación</div>
    <div className="p-2 bg-slate-300">{serviceTypeMapper(type)}</div>
  </div>
);

export default CourseType;
