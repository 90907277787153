import React, { FC } from 'react';

export interface InfoSetProps {
  title: string;
  pictureField: React.JSX.Element;
  children: React.JSX.Element;
}
export const InfoSet: FC<InfoSetProps> = ({ children, pictureField, title }) => (
  <>
    <h2 className="text-2xl text-center font-bold w-full">{title}</h2>
    <div className="flex w-full gap-4">
      <div className="w-2/6">{pictureField}</div>
      <div className="w-4/6 flex flex-col gap-2 p-1">{children}</div>
    </div>
  </>
);
