import { Input } from '@common/components/molecules/Inputs';
import React, { FC } from 'react';
import { Control } from 'react-hook-form';

export interface SearchBoxProps {
  control: Control;
}

export const SearchBox: FC<SearchBoxProps> = ({ control }) => (
  // eslint-disable-next-line jsx-a11y/label-has-associated-control
  <label
    htmlFor="search"
    className="
      flex
      gap-2
      my-4
      p-1
    border-unaula-orange
      border-solid
      border-[1px]
      rounded-2xl
      cursor-pointer"
  >
    <Input
      name="search"
      type="search"
      control={control}
      className="min-w-max flex-grow p-3 outline-none border-none"
    />
    <button type="submit" className="px-3">Buscar</button>
  </label>
);
