import React from 'react';
import { Outlet } from 'react-router-dom';
import { Menu } from '../../organisms/Menu/Menu';
import UnaulaLogo from '../../../../../../assets/images/unaula-logo.png';

export const PrivateRoot = () => (
  <div className="flex">
    <div className="w-1/6"><Menu /></div>
    <div className="w-5/6">
      <div className="flex flex-col" />
      <img src={UnaulaLogo} alt="unaula-logo" className="mx-auto my-2" />
      <div className="my-2">
        <Outlet />
      </div>
    </div>
  </div>
);
