import { AuthStore } from '@common/store/auth.store';
import React, { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const Logout: FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    AuthStore.getState().logout();
    navigate('/', { replace: true });
  }, []);

  return <div>Cerrando sessión...</div>;
};
