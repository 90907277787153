import { ICourse } from '@commonPrivate/types/course.interface';

export const serviceTypeMapper = (type: ICourse['type']) => {
  const types = {
    course: 'Curso',
    workshop: 'Taller',
    project: 'Proyecto',
    seminar: 'Seminario',
    diploma: 'Diplomado',
  };

  return types[type];
};
