import CourseFilters from '@homepage/components/molecules/CourseFilters';
import SearchBox from '@homepage/components/molecules/SearchBox';
import React, { FC } from 'react';
import { UseFormReturn } from 'react-hook-form';

export interface HomeFiltersFields {
  search: string;
  type: string;
}

export interface CardsFiltersProps {
  control: UseFormReturn<any>['control'];
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
}

export const CardsFilters: FC<CardsFiltersProps> = ({ control, onSubmit }) => (
  <header>
    <form onSubmit={onSubmit}>
      <SearchBox control={control} />
      <CourseFilters control={control} />
    </form>
  </header>
);
