import { ForbiddenIcon } from '@common/components/atoms/icons';
import React, { FC } from 'react';

export interface ErrorProps {
  message: string;
}

export const Error: FC<ErrorProps> = ({ message }) => (
  <div className="bg-unaula-red py-3 px-8 rounded-md text-[#fff] flex items-center gap-3">
    <ForbiddenIcon className="fill-[#fff]" />
    {message}
  </div>
);
