import { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';

export const useGet = <T>(request: Promise<AxiosResponse<T>>) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [data, setData] = useState<T | null>(null);

  useEffect(() => {
    setIsLoading(true);
    request
      .then((res) => setData(res.data))
      .catch(() => setIsError(true))
      .finally(() => setIsLoading(false));
  }, [request]);

  return { data, isLoading, isError };
};
