import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { HttpStatusCode } from 'axios';
import { pygloApi } from '@common/services/pyglo-api.service';
import CourseForm from '@courseForm/components/templates/CourseForm';
import { CourseFormFields } from '@courseForm/types/courseFormFields';
import { useRequest } from '@common/hooks/useRequest';
import { ReactSweetAlertOptions } from 'sweetalert2-react-content';
import { useNavigate } from 'react-router-dom';
import { FormModeEnum } from '@common/types/courseForm.enum';
import { MySwal } from '../../../common/utils/MySwal';

const defaultValues: Partial<CourseFormFields> = {
  name: '',
  type: '',
  syllabus: '',
  discounts: '',
  organizedBy: '',
  generalInfo: '',
  exhibitorName: '',
  inscriptionUrl: '',
  supportedByName: '',
};

const errorAlert: ReactSweetAlertOptions = {
  icon: 'error',
  title: 'Oops...',
  text: 'Algo salio mal al crear el servicio de formación',
};

export const NewCoursePage = () => {
  const {
    control, handleSubmit, setValue, reset,
  } = useForm<CourseFormFields>({
    defaultValues,
  });

  const { sendRequest: sendPost, isLoading, isError } = useRequest();
  const navigate = useNavigate();

  useEffect(() => {
    if (isError) {
      MySwal.fire(errorAlert);
    }
  }, [isError]);

  const onSubmit = async (data: CourseFormFields) => {
    const formData = new FormData();
    Object.entries(data).forEach(([key, value]) => {
      formData.append(key, value);
    });

    try {
      const response = await sendPost(pygloApi.post('services/create', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      }));
      if (response.status === HttpStatusCode.Created) {
        reset(defaultValues);
        MySwal.fire({ icon: 'success', title: 'Listo!', text: 'El servicio se ha creado correctamente' });
        navigate('/courses');
      }
    } catch (error) {
      MySwal.fire(errorAlert);
    }
  };

  return (
    <CourseForm
      formMode={FormModeEnum.CREATE}
      control={control}
      isLoading={isLoading}
      onSubmit={handleSubmit(onSubmit)}
      setValue={setValue}
    />
  );
};
