import { BookIcon } from '@common/components/atoms/icons';
import React, { FC } from 'react';

export interface CourseTitleProps {
  align?: 'center' | 'left',
  title: string;
}

export const CourseTitle: FC<CourseTitleProps> = ({ align, title }) => (
  <div className={(align === 'center' ? 'justify-center' : '').concat(' align-center flex items-center gap-1')}>
    <div className="size-6 mr-2">
      <BookIcon className="fill-unaula-orange size-6" />
    </div>
    <h2 className="max-h-16 w-max text-2xl font-bold line-clamp-2">{title}</h2>
  </div>
);

CourseTitle.defaultProps = {
  align: 'left',
};
