import React, { ButtonHTMLAttributes, FC, PropsWithChildren } from 'react';
import { PriorityProps, buttonTheme } from './common/priorityProps';

export type ButtonProps =
PropsWithChildren<ButtonHTMLAttributes<HTMLButtonElement>> & PriorityProps;

export const Button:
FC<ButtonProps> = (
  {
    children,
    className,
    priority = 'primary',
    ...props
  },
) => (
  <button
    type="button"
    className={buttonTheme[priority].concat(' ', className)}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  >
    {children}
  </button>
);
