import { ICourse } from '@commonPrivate/types/course.interface';
import { create } from 'zustand';

interface StateProperties {
  isOpen: boolean;
  course?: ICourse;
}

interface CourseModalState extends StateProperties {
  openModal: (course: ICourse) => void;
  closeModal: () => void;
}

export const useCourseModalStore = create<CourseModalState>((set) => ({
  isOpen: false,
  course: undefined,
  openModal: (course: ICourse) => set({ isOpen: true, course }),
  closeModal: () => set({ isOpen: false, course: undefined }),
}));
