import { Button, ButtonLink } from '@common/components/atoms/Buttons';
import { Input } from '@common/components/molecules/Inputs';
import Error from '@login/components/molecules/Error';
import React, { FC } from 'react';
import { Control } from 'react-hook-form';

export interface LoginProps {
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  error: string | null;
  control: Control<any, any>,
}

export const Login: FC<LoginProps> = ({ onSubmit, error, control }) => (
  <div className="bg-unaula-gray m-5 py-20 px-5 shadow-unaula-box rounded-md">
    <form className="flex flex-col gap-4 w-full lg:w-5/12 m-auto items-center" onSubmit={onSubmit}>
      <h1 className="text-center font-bold text-2xl w-full">Módulo Administración de Cursos</h1>

      {error && <Error message={error} />}

      <Input
        control={control}
        placeholder="Usuario"
        type="email"
        className="block w-full lg:w-80"
        name="email"
        rules={{ required: true }}
      />

      <Input
        control={control}
        placeholder="Contraseña"
        type="password"
        className="block w-full lg:w-80"
        name="password"
        rules={{ required: true }}
      />

      <Button type="submit" className="w-full lg:w-4/12">Ingresar</Button>
      <ButtonLink to="/" className="w-full lg:w-4/12" priority="secondary">Ir a cursos</ButtonLink>
    </form>
  </div>
);
