import { useGet } from '@common/hooks/useGet';
import { useRequest } from '@common/hooks/useRequest';
import NoFound from '@common/pages/NoFound';
import { pygloApi } from '@common/services/pyglo-api.service';
import { FormModeEnum } from '@common/types/courseForm.enum';
import { PygloResponse } from '@common/types/pygloResponse.interface';
import { ICourse } from '@commonPrivate/types/course.interface';
import { MySwal } from '@commonPrivate/utils/MySwal';
import CourseForm from '@courseForm/components/templates/CourseForm';
import { CourseFormFields } from '@courseForm/types/courseFormFields';
import { HttpStatusCode } from 'axios';
import React, { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { ReactSweetAlertOptions } from 'sweetalert2-react-content';

const errorAlert: ReactSweetAlertOptions = {
  icon: 'error',
  title: 'Oops...',
  text: 'Algo salio mal al crear el servicio de formación',
};

export const EditCoursePage = () => {
  const { id } = useParams();

  const getCourseById = useMemo(() => pygloApi.get(`services/${id}`), [id]);

  const { data, isLoading, isError } = useGet<PygloResponse<ICourse>>(getCourseById);
  const { sendRequest: sendPatch } = useRequest();
  const navigate = useNavigate();
  const {
    control, handleSubmit, setValue, reset,
  } = useForm<CourseFormFields>(
    { },
  );

  useEffect(() => {
    if (data?.isSuccess) {
      const {
        image, exhibitorPhoto, supportedByPhoto, ...service
      } = data.data;
      reset(
        {
          ...service,
        },
      );
    }
  }, [data]);
  const onSubmit = async (updatedData: CourseFormFields) => {
    const formData = new FormData();

    Object.entries(updatedData).forEach(([key, value]) => {
      if (value) formData.append(key, value);
    });
    try {
      const response = await sendPatch(pygloApi.patch(`services/${id}`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      }));
      if (response.status === HttpStatusCode.NoContent) {
        MySwal.fire({ icon: 'success', title: 'Listo!', text: 'El servicio se ha actualizado correctamente' });
        navigate('/courses');
      }
    } catch (error) {
      MySwal.fire(errorAlert);
    }
  };

  if (isError) return <NoFound />;

  return (
    isLoading
      ? <> Loading...</>
      : (
        <CourseForm
          formMode={FormModeEnum.UPDATE}
          control={control}
          isLoading={isLoading}
          onSubmit={handleSubmit(onSubmit)}
          setValue={setValue}
        />
      )

  );
};
