import React from 'react';
import { useController } from 'react-hook-form';
import { inputClasses, InputProps } from './Input';

export const TextArea:
React.FC<Omit<InputProps, 'type'>> = (
  {
    className, placeholder, ...props
  },
) => {
  const { field, fieldState } = useController({ ...props });
  return (
    <>
      <textarea
        rows={5}
        className={inputClasses.concat(' ', className ?? '')}
        placeholder={placeholder}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...field}
      />
      <span className="text-unaula-red">{fieldState.error?.message}</span>
    </>
  );
};
