import React, { useEffect, useState } from 'react';
import { Control, Controller } from 'react-hook-form';

export interface PillProps {
  content: string;
  name: string;
  value: string;
  control: Control;
  currentPicked: string;
  onClick: (value: string) => void;
}

const activeClasses = 'px-6 py-1 bg-unaula-orange rounded-3xl text-[#fff] shadow-lg';
const noActiveClasses = 'px-6 py-1 border-unaula-orange border-solid border-[1px] rounded-3xl shadow-lg';

export const Pill: React.FC<PillProps> = ({
  content, name, value, currentPicked, onClick, control,
}) => {
  const [classes, setClasses] = useState<string>(noActiveClasses);

  useEffect(() => {
    if (currentPicked === value) setClasses(activeClasses);
    else setClasses(noActiveClasses);
  }, [currentPicked]);

  return (
    <>
      <label
        htmlFor={`${name}-${value}`}
        className={classes}
      >
        {content}
      </label>
      <Controller
        name={name}
        control={control}
        defaultValue=""
        render={({ field: { onChange } }) => (
          <input
            type="radio"
            name={name}
            id={`${name}-${value}`}
            onChange={(e) => { onChange(e); onClick(value); }}
            value={value}
            defaultChecked={currentPicked === value}
            className="hidden"
          />
        )}
      />

    </>
  );
};
