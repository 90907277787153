export interface Filter {
  column: string;
  operation: string;
  value: any;
}

export const parseParams = (filters: Filter[]) => {
  let query = '';

  filters.forEach((filter, i) => {
    query += `&filters[${i}][column]=${filter.column}`;
    query += `&filters[${i}][operation]=${filter.operation}`;
    query += `&filters[${i}][value]=${filter.value}`;
  });

  return query;
};
