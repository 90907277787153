import { pygloApi } from '@common/services/pyglo-api.service';
import { useMemo, useState } from 'react';
import { PygloResponse } from '@common/types/pygloResponse.interface';
import { ICourse } from '@commonPrivate/types/course.interface';
import { useGet } from './useGet';

export interface UseCoursesProps {
  defaultSkip: number;
  defaultTaking: number;
  queryFilters: string;
}
export const useCourses = ({ defaultSkip, defaultTaking, queryFilters }: UseCoursesProps) => {
  const [skip, setSkip] = useState(defaultSkip);
  const [taking, setTaking] = useState(defaultTaking);
  const [resendCount, setResendCount] = useState(0);

  const getCourses = useMemo(
    () => pygloApi.get(`services?skip=${skip}&taking=${taking}${queryFilters}`),
    [skip, taking, queryFilters, resendCount],
  );

  const {
    data,
    isError,
    isLoading,
  } = useGet<Required<PygloResponse<ICourse[]>>>(getCourses);

  const resendGetCourses = () => setResendCount((prev) => prev + 1);

  const setNextPage = ({ selected }: { selected: number }) => {
    const newSkip = selected * taking;
    setSkip(newSkip);
  };

  return {
    resendGetCourses,
    courses: data?.data ?? [],
    coursesCount: data?.count ?? 0,
    isLoading,
    isError,
    skip,
    setNextPage,
    taking,
    setTaking,
  };
};
