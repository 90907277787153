import React, { FC, PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';
import { PriorityProps, buttonTheme } from './common/priorityProps';

export type ButtonLinkProps = PropsWithChildren<PriorityProps & {
  to: string,
  className?: string,
  replace?: boolean,
}>;

export const ButtonLink:
FC<ButtonLinkProps> = ({
  children, to, priority = 'primary', className, replace,
}) => (
  <Link
    className={buttonTheme[priority].concat(' ', className, ' ', 'block w-min')}
    to={to}
    replace={replace}
  >
    {children}
  </Link>
);

ButtonLink.defaultProps = {
  className: '',
  replace: false,
};
