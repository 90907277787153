import React, { useState, useId } from 'react';
import {
  BookIcon, HomeIcon, PlusIcon, SignOutIcon,
} from '@common/components/atoms/icons';
import { MenuItem, MenuItemProps } from '../../molecules/MenuItem/MenuItem';
import MenuSeparator from '../../molecules/MenuSeparator';

export const Menu = () => {
  const [menuItems] = useState<MenuItemProps[]>([
    {
      content: 'Inicio',
      icon: <HomeIcon className="fill-[#fff]" />,
      to: '/',
    },
    {
      content: 'Servicios de formación',
      icon: <BookIcon className="fill-[#fff]" />,
      to: '/courses',
    },
    {
      content: 'Añadir',
      icon: <PlusIcon className="fill-[#fff]" />,
      to: '/courses/new',
    },
  ]);

  return (
    <div className="flex flex-col bg-unaula-red text-[#fff] h-screen py-5 sticky top-0">
      <MenuSeparator title="Cursos" />
      {
        menuItems.map((item) => (
          <MenuItem
            key={useId()}
            content={item.content}
            icon={item.icon}
            to={item.to}
          />
        ))
      }

      <MenuSeparator title="Sesión" />

      <MenuItem
        content="Cerrar sesión"
        icon={<SignOutIcon className="fill-[#fff]" />}
        to="logout"
      />
    </div>
  );
};
