import React, { FC, JSX } from 'react';
import { NavLink } from 'react-router-dom';

export interface MenuItemProps {
  to: string;
  content: string;
  icon: JSX.Element;
}

export const MenuItem: FC<MenuItemProps> = ({ content, icon, to }) => (
  <NavLink to={to} className="flex flex-nowrap items-center px-5 py-2 gap-3">
    <span>{icon}</span>
    <span className="text-nowrap text-ellipsis overflow-hidden">{content}</span>
  </NavLink>
);
