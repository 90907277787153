import React, { FC } from 'react';
import ReactPaginate, { ReactPaginateProps } from 'react-paginate';

export interface PaginationProps extends ReactPaginateProps {
  showing: number;
  total: number;
}

export const Pagination: FC<PaginationProps> = ({
  breakLabel = '...',
  nextLabel = 'Siguiente',
  previousLabel = 'Anterior',
  pageRangeDisplayed,
  pageCount,
  showing,
  total,
  onPageChange,
  renderOnZeroPageCount,
}) => (
  <div className="container items-center relative">
    <ReactPaginate
      breakLabel={breakLabel}
      nextLabel={nextLabel}
      previousLabel={previousLabel}
      onPageChange={onPageChange}
      pageRangeDisplayed={pageRangeDisplayed}
      pageCount={pageCount}
      renderOnZeroPageCount={renderOnZeroPageCount}
      activeClassName="bg-unaula-orange text-white"
      pageClassName="py-2 px-4 rounded-2xl"
      containerClassName="flex m-auto justify-center items-center gap-5"
    />
    <div className="text-center m-2 sm:m-0 sm:absolute sm:top-[50%] sm:right-1 sm:translate-y-[-50%]">
      { `Mostrando ${showing} de ${total}` }
    </div>
  </div>
);
