import { AuthStore } from '@common/store/auth.store';
import { FC } from 'react';

export interface SwitchRouteProps {
  renderOnLogged: JSX.Element;
  renderOnPublic: JSX.Element;
}

export const SwitchRoute: FC<SwitchRouteProps> = (
  { renderOnLogged: loggedLayout, renderOnPublic: publicLayout },
) => {
  const isLogged = AuthStore((state) => state.isAuthenticated);
  return isLogged ? loggedLayout : publicLayout;
};
