import React, { useEffect } from 'react';
import { useCourses } from '@common/hooks/useCourses';
import { useRequest } from '@common/hooks/useRequest';
import { pygloApi } from '@common/services/pyglo-api.service';
import { MySwal } from '@commonPrivate/utils/MySwal';
import Courses from '@courses/components/templates/CoursesAdminList';

export const CoursesPage = () => {
  const {
    courses,
    coursesCount,
    isError,
    isLoading,
    taking,
    resendGetCourses,
    setNextPage,
  } = useCourses({
    defaultSkip: 0,
    defaultTaking: 10,
    queryFilters: '',
  });

  const {
    sendRequest,
    isLoading: isLoadingDelete,
    isError: isErrorDelete,
  } = useRequest();

  useEffect(() => {
    if (isErrorDelete) MySwal.fire({ icon: 'error', title: 'Oops...', text: 'Algo salio mal al eliminar el curso' });
  }, [isErrorDelete]);

  const handleDelete = async (id: number) => {
    try {
      await sendRequest(pygloApi.delete(`services/${id}`));
      resendGetCourses();
      MySwal.fire({ icon: 'success', title: 'Listo!', text: 'El curso se ha eliminado correctamente' });
    } catch (error) {
      MySwal.fire({ icon: 'error', title: 'Oops...', text: 'Algo salio mal al eliminar el curso' });
    }
  };

  if (isError) {
    return <p className="text-center m-auto py-32">Upss, ha ocurrido un error inesperado.</p>;
  }

  return (
    <Courses
      courses={courses}
      coursesCount={coursesCount}
      setNextPage={setNextPage}
      isLoading={isLoading}
      taking={taking}
      isLoadingDelete={isLoadingDelete}
      handleDelete={handleDelete}
    />
  );
};
