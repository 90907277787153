import { create } from 'zustand';
import { getStorage, removeStorage, setStorage } from '@common/utils/localStorage.utils';

export const tokenStorageKey = 'token';

interface AuthStateProperties {
  isAuthenticated: boolean;
}

interface AuthState extends AuthStateProperties {
  login: (token: string) => void;
  logout: () => void;
}

export const AuthStore = create<AuthState>((set) => ({
  isAuthenticated: !!getStorage(tokenStorageKey),
  login(token) {
    setStorage(tokenStorageKey, token);
    set({ isAuthenticated: true });
  },
  logout() {
    removeStorage(tokenStorageKey);
    set({ isAuthenticated: false });
  },
}));
