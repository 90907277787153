import React, { FC } from 'react';
import { PlusIcon } from '@common/components/atoms/icons';
import { InputProps } from '@common/components/molecules/Inputs/Input';
import { useController } from 'react-hook-form';

export interface FileInputProps extends Omit<InputProps, 'type'> {
  id: string;
  label: string;
}

const FileInput: FC<FileInputProps> = ({
  id, label, ...props
}) => {
  const { field, fieldState } = useController({ ...props });

  return (
    <>
      <label htmlFor={id} className="px-4 py-6 rounded-2xl bg-unaula-orange text-[#fff] block min-w-fit text-center cursor-pointer">
        {
          field.value
            ? <img src={URL.createObjectURL(field.value)} className="h-32 w-auto object-cover m-auto" alt="" /> : (
              <>
                <PlusIcon className="fill-[#fff] size-24 m-auto" />
                <span>{label}</span>
              </>
            )
        }

        <input
          type="file"
          className="hidden"
          accept=".jpg,.png"
          id={id}
          /* eslint-disable-next-line react/jsx-props-no-spreading */
          {...field}
          name={field.name}
          value={field.value?.fileName}
          defaultValue={field.value}
          onChange={(event) => {
            field.onChange(event.target.files[0]);
          }}
        />

      </label>
      { fieldState.error && <span className="text-unaula-red">{fieldState.error.message}</span> }
    </>

  );
};

export default FileInput;
