import React from 'react';
import { createBrowserRouter } from 'react-router-dom';
import PrivateRoot from '@commonPrivate/components/templates/PrivateRoot';
import PageRoot from '@commonPublic/components/templates/PageRoot';
import Logout from '@commonPrivate/pages/logout';
import LoginPage from '@login/pages/LoginPage';
import NewCoursePage from '@courseForm/pages/NewCoursePage';
import CoursesPage from '@courses/pages/CoursesPage';
import HomePage from '@homepage/pages/Home';
import EditCoursePage from '@courseForm/pages/EditCoursePage';
import { SwitchRoute, PrivateRoute, PublicRoute } from '../RouteVisibility';
import NoFound from '../NoFound';

export const Router = createBrowserRouter([
  {
    path: '/',
    element: <SwitchRoute
      renderOnLogged={<PrivateRoot />}
      renderOnPublic={<PageRoot />}
    />,
    children: [
      {
        path: '/',
        element: <HomePage />,
      },
      {
        path: '/login',
        element: (
          <PublicRoute>
            <LoginPage />
          </PublicRoute>
        ),
      },
      {
        path: 'courses',
        element: (
          <PrivateRoute>
            <CoursesPage />
          </PrivateRoute>
        ),
      },
      {
        path: 'courses/new',
        element: (
          <PrivateRoute>
            <NewCoursePage />
          </PrivateRoute>
        ),
      },
      {
        path: 'courses/edit/:id',
        element: (
          <PrivateRoute>
            <EditCoursePage />
          </PrivateRoute>
        ),
      },
      {
        path: 'logout',
        element: (
          <PrivateRoute>
            <Logout />
          </PrivateRoute>
        ),
      },
      {
        path: '*',
        element: <NoFound />,
      },
    ],
  },
]);
