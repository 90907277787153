import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { pygloApi } from '@common/services/pyglo-api.service';
import { AuthStore } from '@common/store/auth.store';
import Login from '@login/components/templates/Login';

export interface LoginInputs {
  email: string;
  password: string;
}

export const LoginPage: FC = () => {
  const navigate = useNavigate();
  const login = AuthStore((state) => state.login);
  const [loginError, setLoginError] = React.useState<string | null>(null);
  const { handleSubmit, control } = useForm<LoginInputs>({
    defaultValues: { email: '', password: '' },
  });

  const handleLogin = async ({ email, password }: LoginInputs) => {
    try {
      const response = await pygloApi.post('/admin/login', { email, password });
      login(response.data.token);
      navigate('/', { replace: true });
    } catch (error) {
      setLoginError('Ususario o contraseña incorrectos');
    }
  };

  return <Login onSubmit={handleSubmit(handleLogin)} control={control} error={loginError} />;
};
