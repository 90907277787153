import React, { FC, useId } from 'react';

const rowTheme = {
  header: 'bg-unaula-orange text-[#fff] font-bold',
  body: 'bg-unaula-row-gray',
};

const cellStyle = 'first:rounded-l-2xl last:rounded-r-2xl';

export interface TableHeaderProps {
  cells: (React.JSX.Element | string)[];
  theme?: 'header' | 'body';
}
export const TableRow: FC<TableHeaderProps> = ({ cells, theme }) => (
  <tr className={'overflow-hidden rounded-3xl shadow-xl'.concat(' ', rowTheme[theme])}>
    {
      cells.map((row) => (
        theme === 'header'
          ? <th className={'py-2 px-5 text-left'.concat(' ', cellStyle)} key={useId()}>{row}</th>
          : <td className={'p-5 font-semibold'.concat(' ', cellStyle)} key={useId()}>{row}</td>
      ))
    }
  </tr>
);

TableRow.defaultProps = {
  theme: 'body',
};
