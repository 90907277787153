import React, { FC } from 'react';
import { Control, UseFormSetValue } from 'react-hook-form';
import { Button } from '@common/components/atoms/Buttons';
import { Input, TextArea } from '@common/components/molecules/Inputs';
import AdminContainer from '@commonPrivate/components/atoms/AdminContainer';
import FileInput from '@courseForm/components/molecules/FileInput';
import InfoSet from '@courseForm/components/molecules/InfoSet';
import TagInput from '@courseForm/components/molecules/TagInput';
import { defaultRules } from '@common/utils/defaultRules.util';
import { CourseFormFields } from '@courseForm/types/courseFormFields';
import { Select } from '@common/components/molecules/Inputs/Select';
import { Spinner } from '@common/components/atoms/Loaders';
import { FormModeEnum } from '@common/types/courseForm.enum';

export interface CourseFormProps {
  control: Control<any, any>;
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  setValue: UseFormSetValue<CourseFormFields>,
  isLoading: boolean;
  formMode: FormModeEnum;
}

export const CourseForm: FC<CourseFormProps> = ({
  control, isLoading, onSubmit, setValue, formMode,
}) => (
  <AdminContainer>
    <form className="flex flex-col items-center gap-4" encType="multipart/form-data" onSubmit={(event) => onSubmit(event)}>
      <InfoSet
        title="Servicio de formación"
        pictureField={(
          <FileInput
            id="image"
            label="Agrega una portada"
            name="image"
            control={control}
            rules={formMode === FormModeEnum.CREATE ? defaultRules : { required: false }}
          />
        )}
      >
        <>
          <Input
            name="name"
            type="text"
            placeholder="Escriba aquí el nombre del curso"
            control={control}
            rules={defaultRules}
          />
          <Select
            name="type"
            placeholder="Selecciona el tipo de servicio"
            control={control}
            rules={defaultRules}
          >
            <option value="course">Curso</option>
            <option value="seminar">Seminario</option>
            <option value="diploma">Diploma</option>
            <option value="workshop">Taller</option>
          </Select>
          <Input
            name="inscriptionUrl"
            type="text"
            placeholder="Agrega la URL de inscripción"
            control={control}
            rules={{ ...defaultRules, pattern: { value: /^https?:\/\//, message: 'Debe ser una URL' } }}
          />
        </>
      </InfoSet>

      <TextArea
        name="generalInfo"
        placeholder="Escribe aquí una descripción del curso"
        control={control}
        className="w-full"
        rules={defaultRules}
      />

      <InfoSet
        title="Expositor / temas"
        pictureField={(
          <FileInput
            id="exhibitorPhoto"
            label="Agrega una foto del expositor"
            name="exhibitorPhoto"
            control={control}
            rules={formMode === FormModeEnum.CREATE ? defaultRules : { required: false }}
          />
        )}
      >
        <>
          <Input
            name="exhibitorName"
            type="text"
            placeholder="Nombre del expositor"
            control={control}
            rules={defaultRules}
          />
          <Input
            name="hours"
            type="number"
            placeholder="Total de horas"
            control={control}
            rules={{ ...defaultRules, min: { value: 1, message: 'Debe ser mayor a 0' } }}
          />
          <Input
            name="cost"
            type="number"
            placeholder="Total de inversión"
            control={control}
            rules={{ ...defaultRules, min: { value: 1, message: 'Debe ser mayor a 0' } }}
          />
          <TagInput control={control} setValue={setValue} />
        </>
      </InfoSet>

      <InfoSet
        title="Organizadores"
        pictureField={(
          <FileInput
            id="supportedByPhoto"
            label="Foto de la entidad que apoya"
            name="supportedByPhoto"
            control={control}
            rules={formMode === FormModeEnum.CREATE ? defaultRules : { required: false }}
          />
        )}
      >
        <>
          <Input
            name="organizedBy"
            type="text"
            placeholder="Escribe el nombre del organizador"
            control={control}
            rules={defaultRules}
          />
          <Input
            name="supportedByName"
            type="text"
            placeholder="Escribe el nombre de la organización que apoya"
            control={control}
            rules={defaultRules}
          />
          <Input
            name="discounts"
            type="text"
            placeholder="Describa los descuentos aplicacbles"
            control={control}
            rules={defaultRules}
          />
        </>
      </InfoSet>

      <Button
        disabled={isLoading}
        type="submit"
        className={`w-max flex gap-3 ${isLoading ? ' cursor-not-allowed  opacity-70' : ''}`}
      >
        <span>
          { formMode === FormModeEnum.UPDATE ? 'Actualizar servicio de formación' : 'Crear servicio de formación'}
        </span>
        {isLoading && <Spinner size="sm" /> }
      </Button>
    </form>
  </AdminContainer>
);
