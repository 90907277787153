import Banner from '@commonPublic/components/atoms/Banner';
import Footer from '@commonPublic/components/organisms/Footer';
import React from 'react';
import { Outlet } from 'react-router-dom';

export const PageRoot = () => (
  <div className="container m-auto">
    <Banner />
    <Outlet />
    <Footer />
  </div>
);
