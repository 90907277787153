import Pill from '@homepage/components/atoms/Pill';
import React, {
  FC, useEffect, useRef, useState,
} from 'react';
import { Control } from 'react-hook-form';
import { Link } from 'react-router-dom';

export interface CourseFiltersProps {
  control: Control<any>;
}

export const CourseFilters: FC<CourseFiltersProps> = ({ control }) => {
  const [currentPicked, setCurrentPicked] = useState('');

  const btnRef = useRef<HTMLButtonElement>(null);

  const handlePillClick = (value: string) => {
    setCurrentPicked(value);
  };

  useEffect(() => {
    btnRef.current?.click();
  }, [currentPicked]);

  return (
    <>
      <h3 className="font-bold">Categorías</h3>

      <hr className="my-2" />

      {/* Todo: refactor the introduced technical debt here */}
      {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
      <button ref={btnRef} type="submit" className="hidden" />

      <div className="flex items-center my-3 flex-wrap">
        <div className="flex gap-1 flex-grow flex-wrap justify-center lg:justify-start">
          <Pill
            control={control}
            name="type"
            content="Todos"
            value=""
            onClick={handlePillClick}
            currentPicked={currentPicked}
          />
          <Pill
            control={control}
            name="type"
            content="Seminarios"
            value="seminar"
            onClick={handlePillClick}
            currentPicked={currentPicked}
          />
          <Pill
            control={control}
            name="type"
            content="Cursos"
            value="course"
            onClick={handlePillClick}
            currentPicked={currentPicked}
          />
          <Pill
            control={control}
            name="type"
            content="Talleres"
            value="workshop"
            onClick={handlePillClick}
            currentPicked={currentPicked}
          />
          <Pill
            control={control}
            name="type"
            content="Diplomados"
            value="diploma"
            onClick={handlePillClick}
            currentPicked={currentPicked}
          />
        </div>

        <Link
          to="login"
          className="bg-unaula-orange rounded-2xl block px-4 py-2 text-[#fff] shadow-lg w-full lg:w-auto text-center my-2 lg:m-auto"
        >
          Administrar
        </Link>
      </div>
    </>
  );
};
