import React, { FC, PropsWithChildren } from 'react';
import { useController } from 'react-hook-form';
import { inputClasses, InputProps } from './Input';

export const Select: FC<Omit<InputProps, 'type'> & PropsWithChildren> = (
  {
    className, placeholder, id, children, ...props
  },
) => {
  const { field, fieldState } = useController({ ...props });
  return (
    <>
      <select
        className={inputClasses.concat(' ', className ?? '')}
        id={id ?? props.name}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...field}
      >
        <option disabled value="">{placeholder}</option>
        {children}
      </select>
      <span className="text-unaula-red">{fieldState.error?.message}</span>
    </>
  );
};
