import React, { FC } from 'react';
import { ICourse } from '@commonPrivate/types/course.interface';
import CourseCard from '../CourseCard';

export interface CardListProps {
  courses: ICourse[];
}

export const CardList: FC<CardListProps> = ({ courses }) => (
  <div className="container mx-auto">
    <div className="flex flex-row flex-wrap justify-around lg:justify-between gap-7 w-fit">
      {
        courses.map((course) => (
          <CourseCard course={course} key={`course-key${course.id}`} />
        ))
      }
      {!courses.length && <h4>No hay ningún servicio de formación</h4>}
    </div>
  </div>
);
