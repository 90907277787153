import React, { FC, useEffect, useState } from 'react';
import { Button, ButtonLink } from '@common/components/atoms/Buttons';
import { PenIcon, TrashIcon } from '@common/components/atoms/icons';
import { Spinner } from '@common/components/atoms/Loaders';
import { MySwal } from '@commonPrivate/utils/MySwal';
import TableRow from '@courses/components/molecules/TableRow';
import { CoursesListProps } from '@common/types/courseListProps.interface';
import { Pagination } from '@common/components/organisms/Pagination/Pagination';
import { serviceTypeMapper } from '@common/utils/serviceTypeMapper.util';

export interface CoursesTableProps extends CoursesListProps {
  isLoadingDelete: boolean;
  handleDelete: (id: number) => void;
}

export const CoursesTable: FC<CoursesTableProps> = ({
  courses, coursesCount, isLoading, taking, isLoadingDelete, setNextPage, handleDelete,
}) => {
  const [pageCount, setPageCount] = useState(0);

  useEffect(() => {
    setPageCount(Math.ceil(coursesCount / taking));
  }, [coursesCount]);

  const handleDeleteClick = (id: number) => {
    MySwal.fire({
      icon: 'question',
      text: '¿Estás seguro de eliminar este curso?',
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonText: 'Eliminar',
      cancelButtonText: 'Cancelar',
      cancelButtonColor: 'green',
      confirmButtonColor: 'red',
      focusCancel: true,
    }).then(({ isConfirmed }) => {
      if (isConfirmed) handleDelete(id);
    });
  };

  return (
    <table className="table w-full border-spacing-y-4 border-separate">
      <thead>
        <TableRow
          theme="header"
          cells={['Nombre', 'Tipo', 'Editar', 'Eliminar']}
        />
      </thead>

      <tbody>
        {
          isLoading && courses.length === 0 ? (
            <tr>
              <td colSpan={4} className="p-50 text-center">Cargando...</td>
            </tr>
          )
            : courses.map((course) => (
              <TableRow
                key={course.id}
                theme="body"
                cells={[
                  course.name,
                  serviceTypeMapper(course.type),
                  <ButtonLink
                    to={`edit/${course.id}`}
                    priority="primary"
                    className="py-2"
                  >
                    <PenIcon className="" />
                  </ButtonLink>,
                  <Button
                    priority="secondary"
                    className="py-2"
                    onClick={() => handleDeleteClick(course.id)}
                  >
                    { isLoadingDelete ? <Spinner size="sm" /> : <TrashIcon className="" /> }
                  </Button>,
                ]}
              />
            ))
        }
      </tbody>

      <tfoot>
        <tr>
          {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
          <td colSpan={4}>
            <Pagination
              onPageChange={setNextPage}
              pageRangeDisplayed={5}
              pageCount={pageCount}
              renderOnZeroPageCount={undefined}
              showing={courses.length}
              total={coursesCount}
            />
          </td>
        </tr>
      </tfoot>
    </table>
  );
};
